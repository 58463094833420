import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { DataContext } from "../dataContext";
import TransferDetails from "../components/TransferDetails";
import BottomButton from "../components/BottomButton";
import SendSms from "../components/SendSMS";
import { ReactComponent as Copy } from "../assets/ico_copy.svg";
import { ReactComponent as Info } from "../assets/ico_info.svg";
import { ROUTE_CONSTANTS } from "../constants/routeConstants";
import useApi from "../api/api";
import { SpinnerCircularFixed } from "spinners-react";
import { isSMSCapable } from "../utils/utils"

const { OUTGOING_DETAILS } = ROUTE_CONSTANTS;

const OutgoingDetails = ({ branding, homePath }) => {
  const api = useApi(false);
  const urlParams = useParams();
  const navigate = useNavigate();
  const [cancelStatus, setCancelStatus] = useState(false);
  const [resendData, setResendData] = useState({});

  const { history, getHistory, notificationFeed } = useContext(DataContext);
  const transfer = history.find(({ id }) => id === urlParams.id);
  const notification = notificationFeed.find(
    ({ transfer_id }) => transfer_id === urlParams.id
    // // notification/feed doesn't work locally, uncomment an option below
    // ({ transfer_id }) => transfer_id === "9754335f-7db7-5f10-9db0-79fd5b6fcb02" // ON NETWORK FAILED
    // ({ transfer_id }) => transfer_id === "17bfb8a4-2fa3-5615-b4b4-e3cc6151c65d" // ON NETWORK SUCCEEDED
    // ({ transfer_id }) => transfer_id === "c35bc840-1865-5bf5-916d-b85ce20589c1" // OFF NETWORK PENDING
  );

  // // for dummy ON NETWORK
  // if (transfer) {
  //   transfer.note = "Dummy data";
  //   transfer.status = notification?.status?.toUpperCase();
  // }

  window.scrollTo(0, 0);
  const canSendSMS = isSMSCapable();

  const shouldShowSmsSend = () => {
    if (transfer !== undefined) {
      return (
        transfer?.recipient?.type === "phone" &&
        canSendSMS &&
        notification?.shortlink
      );
    }
    return false;
  };

  useEffect(() => {
    setResendData({
      identifier: transfer?.recipient?.value,
      children: "",
      disabled: false,
      message: `${branding.sms_customization.off_net_first} $${transfer?.amount?.value} ${branding.sms_customization.off_net_second} ${notification?.financial_institution} ${branding.sms_customization.off_net_third} "${transfer?.note}". ${branding.sms_customization.off_net_fourth} ${notification?.shortlink}`,
    });
  }, [branding, notification, transfer]);

  const cancelTransfer = async () => {
    setResendData({
      ...resendData,
      disabled: true,
    });
    navigate(
      `${OUTGOING_DETAILS.replace(":id", transfer.id).replace(":action", "cancel")}`
    );
    try {
      await api.delete(`/transfer/outgoing/${transfer.id}`);
      setCancelStatus("STARTED");
    } catch (error) {
      console.error("cancel transfer error", error);
      setCancelStatus("ERROR");
    }
  };

  useEffect(() => {
    if (cancelStatus === "STARTED" && transfer.status === "PENDING") {
      const interval = setInterval(() => {
        getHistory();
      }, 3000);
      setTimeout(() => {
        clearInterval(interval);
        setCancelStatus("TIMEDOUT");
      }, 20000);
      return () => clearInterval(interval);
    }
  }, [cancelStatus, getHistory, transfer]);

  const clickCopy = () => {
    navigator.clipboard.writeText(resendData.message);
    document.getElementById("show_click_copy").style.display = "inline";
    setTimeout(() => {
      document.getElementById("show_click_copy").style.display = "none";
    }, 1000);
  };

  const renderCancelled = () => {
    if (cancelStatus === "TIMEDOUT" && transfer.status === "PENDING") {
      return (
        <h2>
          Your transfer cancellation is still processing. Please check the
          status in the 'History' tab later.
        </h2>
      );
    }
    if (cancelStatus === "ERROR") {
      return (
        <h2>Transfer cancel failed. Please contact your bank for more info.</h2>
      );
    }
    if (transfer.status === "FAILED") {
      return <h2>Your transfer has been cancelled.</h2>;
    }
    return (
      <>
        <h1 style={{ marginBottom: "40px" }}>Cancelling Transfer</h1>
        <SpinnerCircularFixed
          color={branding.sending_colors.sending_bg_secondary}
          secondaryColor={branding.sending_colors.sending_bg_primary}
          speed={150}
          style={{ height: "60px", width: "60px" }}
        />
      </>
    );
  };

  return (
    <main className={cancelStatus ? "page_without_nav" : "page_with_nav"}>
      {transfer && transfer.recipient ? (
        <>
          {cancelStatus ? (
            <div
              className="center_page"
              style={{
                flexDirection: "column",
                height: "70%",
                textAlign: "center",
              }}
            >
              {renderCancelled()}
            </div>
          ) : (
            <div>
              {transfer.status !== "PENDING" ? (
                <>
                  <h3 className="status">
                    {transfer.status === "SUCCEEDED" ? (
                      <>Payment was sent</>
                    ) : (
                      <>Payment failed</>
                    )}
                  </h3>
                  <hr style={{ margin: "20px 0" }} />
                </>
              ) : (
                <div style={{ marginBottom: "40px" }}>
                  <h3 className="status">
                    Your transfer is still processing.
                    {transfer.is_cancellable && (
                      <>
                        {" "}
                        The recipient needs to take action to receive the funds.
                      </>
                    )}
                  </h3>
                  {transfer.is_cancellable && (
                    <>
                      {shouldShowSmsSend() && (
                        <div className="transfer_action_info">
                          <div style={{ marginRight: "10px" }}>
                            <Info className="fill_sending_bg_primary stroke_sending_bg_secondary" />
                          </div>
                          <>
                            You can click the button below to message the
                            recipient. It includes the link required to receive
                            the funds.
                          </>
                        </div>
                      )}
                      <hr style={{ margin: "20px 0" }} />
                      {shouldShowSmsSend() ? (
                        <SendSms {...resendData}></SendSms>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {notification?.shortlink && (
                            <div
                              className="status"
                              style={{ fontSize: "12px", fontWeight: 400 }}
                            >
                              <span>
                                {resendData.message?.split("https")[0]}
                                <strong style={{ textDecoration: "underline" }}>
                                  https{resendData.message?.split("https")[1]}
                                </strong>
                              </span>
                              <div style={{ textAlign: "right" }}>
                                <Copy
                                  className="fill_sending_bg_tertiary stroke_sending_bg_secondary"
                                  onClick={() => clickCopy()}
                                  style={{ cursor: "pointer" }}
                                />
                                <div
                                  id="show_click_copy"
                                  style={{ display: "none" }}
                                >
                                  Message copied
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      <button
                        style={{ marginTop: "15px" }}
                        onClick={cancelTransfer}
                      >
                        Cancel Transfer
                      </button>
                    </>
                  )}
                </div>
              )}
              {TransferDetails(transfer)}
            </div>
          )}
        </>
      ) : (
        <div className="center_page">Transfer Not Found</div>
      )}
      {!(cancelStatus === "STARTED" && transfer.status === "PENDING") &&
        BottomButton(() => navigate(homePath), "Return Home")}
    </main>
  );
};

export default OutgoingDetails;
